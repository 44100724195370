import $ from 'jquery'

export default function() {
  $('.js-modal-open').each(function() {
    $(this).on('click', function() {
      const target = $(this).data('target')
      const modal = document.getElementById(target)
      $(modal).fadeIn()
      return false
    })
  })
  $('.js-modal-close').on('click', function() {
    $('.js-modal').fadeOut()
    return false
  })
}
