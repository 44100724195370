import $ from 'jquery'
require('jquery-ui/ui/widgets/datepicker')

$('.datepicker').datepicker({
  monthNames: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
  showAnim: 'show',
  showOtherMonths: true,
  minDate: 0,
  numberOfMonths: 1,
  dateFormat: 'yy/mm/dd',
})
