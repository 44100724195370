/**
 * 指定したCookieの直を取得します
 *
 * @param {String} key
 * @return {String}
 */
export const getCookie = key => {
  if (!key) {
    return
  }
  const pattern = '(?:(?:^|.*s*)' + key + 's*=s*([^;]*).*$)|^.*$'
  return document.cookie.replace(new RegExp(pattern), '$1')
}

/**
 * 指定したCookieの直を設定します
 *
 * @param {String} key
 * @param {String} value
 * @param {Number} [expiresDay=1] 有効期限(日)
 */
export const setCookie = (key, value, expiresDay = 1) => {
  if (!key) {
    return
  }
  const sec = expiresDay ? 60 * 60 * 24 * expiresDay : 0
  document.cookie = `${key}=${value};domain=${location.hostname};path=${location.pathname};max-age=${sec}`
}

/**
 * 指定したCookieの直を削除します
 *
 * @param {String} key
 */
export const removeCookie = key => {
  if (!key || !getCookie(key)) {
    return
  }
  setCookie(key, '', 0)
}
