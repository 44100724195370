import $ from 'jquery'

const trigger = {
  accordion: $('[data-trigger-accordion]'),
}

const target = {
  accordion: $('[data-target-accordion]'),
}

export default function() {
  if (!trigger.accordion.length) return
  trigger.accordion.on('click', function() {
    $(this).toggleClass('is-active')
    $(this)
      .next(target.accordion)
      .stop(false, false)
      .slideToggle(300, 'swing')
  })
}
