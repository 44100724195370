import 'babel-polyfill'
import $ from 'jquery'
import { validate } from 'jquery-validation'
import active from './util/active'
import accordion from './util/accordion'
import modal from './util/modal'
import datePicker from './util/datePicker'
import smoothScroll from './util/smoothScroll'
import customize from './util/customize'
import { headerShow, toTop, footerBanner } from './util/scrollEvent'
import {
  formValid,
  formReset,
  enterDisallow,
  errorJump,
  submitCheck,
} from './util/formValid'
import moment from 'moment'
import * as cookie from './util/cookie'

require('moment/locale/ja')

const COOKIE_REF_ID_KEY = 'refid'

$(() => {
  active()
  accordion()
  modal()
  smoothScroll()
  customize()
  formValid()
  formReset()
  enterDisallow()
  errorJump()
  submitCheck()
  toTop()
  headerShow()
  footerBanner()
  updateCookieByQueryParams()
})

const selectColor = function() {
  // 現在選択されてる項目によって色設定
  const select = $('[data-target-select]')

  if (select.find('option:selected').hasClass('disabled-color')) {
    $('select').css({ color: '#959595' })
  }

  // 項目が変更された時、条件によって色変更
  select.on('change', function() {
    if (
      $(this)
        .find('option:selected')
        .hasClass('disabled-color')
    ) {
      $(this).css({ color: '#959595' })
    } else {
      $(this).css({ color: '#434343' })
    }
  })
}

$(function() {
  selectColor()
})

const hopeTimes = $('#hopeTime').html()

window.onload = function() {
  if (moment().hours() >= 18) {
    $('#date').datepicker('option', 'minDate', '+1d')
  }
  const code = sessionStorage.getItem('code')
  $('#code').text(code)

  writeInput()
}

$('#date').change(function() {
  const dateFormat = 'YYYY/MM/DD'
  const now = moment()
  const input = moment($(this).val())

  $('#hopeTime').html(hopeTimes)

  if (input.isSame(now.format(dateFormat), 'days')) {
    if (now.hours() >= 6 && now.hours() < 18) {
      let hour = 0
      let time = ''
      if (now.minute() > 30) {
        hour = now.hours() + 4
        time = hour + ':00'
      } else {
        hour = now.hours() + 3
        time = hour + ':30'
      }

      $('#hopeTime option').each(function() {
        if (
          $(this)
            .val()
            .indexOf(time) >= 0
        ) {
          $(this).remove()
          return false
        } else if ($(this).val() === '選択してください') {
          // Through
        } else {
          $(this).remove()
        }
      })
    }
  }
})

$('#inputs').submit(function() {
  const tmpArray = $(this).serializeArray()
  const inputTime = tmpArray.find(function(input) {
    return input.name === 'time'
  })

  if (!inputTime) {
    // 時間が日存在時に適当な値を入れておいてあげる
    tmpArray.push({
      name: 'time',
      value: '',
    })
  }

  sessionStorage.setItem('form', JSON.stringify(tmpArray))
  return true
})
let flag = false
$('#submitOK').click(function() {
  if (flag) return
  const inputs = JSON.parse(sessionStorage.getItem('form'))
  sessionStorage.clear()
  flag = true
  const refId = cookie.getCookie(COOKIE_REF_ID_KEY)
  if (refId) {
    inputs[inputs.length] = {
      name: COOKIE_REF_ID_KEY,
      value: refId,
    }
  }

  const cdSucceess = contactData(inputs)
  console.log(cdSucceess)
  if (!cdSucceess) {
    window.alert(
      '通信エラーが発生しました。（contactData通信エラー)\n 再度送信いただくか、管理者に問い合わせをお願いいたします。'
    )
    return
  }

  $.ajax({
    url: '../mail.php',
    type: 'POST',
    dataTypes: 'json',
    data: inputs,
  }).done(function(res) {
    if (res) {
      cookie.removeCookie(COOKIE_REF_ID_KEY)
      sessionStorage.clear()
      sessionStorage.setItem('code', res)
      location.href = './thanks.php'
    }
  })
})

$('#back').click(function() {
  history.back()
})

// Cotnact Data: https://docs.google.com/spreadsheets/d/1ncqhJaXQep0Why6yCcIQCXK5ZXlxIbRT5YMgXYfKlhk/edit#gid=0
// eslint-disable-next-line camelcase
function contactData(form_param) {
  // APIエンドポイント ※テスト・本番で異なる
  // pro
  const url = 'https://prod.cd.012grp.tech/api/v2/entry'
  // dev
  // const url = 'https://dev.cd.012grp.tech/api/v2/entry'
  // eslint-disable-next-line camelcase
  const unique_id = Math.random()
    .toString(32)
    .substring(2)
  const sendData = {
    phone: form_param[3].value,
    others: JSON.stringify({
      会社名: form_param[0].value,
      その他: form_param[9].value,
    }), // JSON形式
    subject: '見積もり【ハルエネLP】',
    click_id: unique_id,
    name: form_param[1].value,
    name_kana: form_param[2].value,
    mail: form_param[4].value,
    zip: form_param[5].value,
    address: form_param[6].value,
    address_2: form_param[7].value + form_param[8].value,
  }
  return $.ajax({
    url: url,
    type: 'POST',
    dataType: 'json',
    data: sendData,
    beforeSend: function(request) {
      // 管理画面サイト詳細記載のtoken
      // pro
      request.setRequestHeader('x-api-key', 'RKHdn1CSQFZQDCKg946f8EhiW')
      // dev
      // request.setRequestHeader('x-api-key', 'LqnxieXUIQmYwyGXMGG9UFOaF')
    },
  })
    .done(data => {
      return true
    })
    .fail(data => {
      return false
    })
}

function writeInput() {
  const inputs = JSON.parse(sessionStorage.getItem('form' || 'null'))

  dispConfirm(inputs, 'corporatename', 'inputCoporateName')
  dispConfirm(inputs, 'name', 'inputName')
  dispConfirm(inputs, 'namekana', 'inputNameKana')
  dispConfirm(inputs, 'tel', 'inputTel')
  dispConfirm(inputs, 'mail', 'inputEmail')
  dispConfirm(inputs, 'zip', 'inputZip')
  dispConfirm(inputs, 'pref', 'inputPref')
  dispConfirm(inputs, 'addr01', 'inputAddr01')
  dispConfirm(inputs, 'addr02', 'inputAddr02')
  dispConfirm(inputs, 'note', 'inputRemark')
}

function dispConfirm(inputs, name, id) {
  if ($('#inputCoporateName').length) {
    const tmp = inputs.find(function(input) {
      return input.name === name
    })
    if (tmp.value !== '0') {
      $('#' + id).text(tmp.value)
    } else {
      $('#' + id).text('')
    }
  }
}

function updateCookieByQueryParams() {
  const refHost = document.referrer
    ? document.referrer.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)[1]
    : null
  if (
    refHost === document.location.host ||
    document.location.pathname !== '/'
  ) {
    return
  }

  const params = {}
  const query = document.location.search.substr(1)
  query.split('&').forEach(q => {
    const [key, value] = q.split('=')
    if (key !== '') {
      params[key] = value !== undefined ? decodeURIComponent(value) : ''
    }
  })

  if (Object.prototype.hasOwnProperty.call(params, COOKIE_REF_ID_KEY)) {
    cookie.setCookie(COOKIE_REF_ID_KEY, params[COOKIE_REF_ID_KEY])
  } else {
    cookie.removeCookie(COOKIE_REF_ID_KEY)
  }
}
