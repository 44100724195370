import $ from 'jquery'

const trigger = {
  active: $('[data-trigger-active]'),
}

export default function() {
  trigger.active.on('touchstart mousedown', function() {
    $(this).addClass('is-active')
  })
  trigger.active.on('touchend mouseup mouseleave', function() {
    $(this).removeClass('is-active')
  })
}
