import $ from 'jquery'

const target = {
  body: $('[data-target-body]'),
  header: $('[data-target-header]'),
  headerShow: $('[data-target-headerShow]'),
  toTop: $('[data-target-totop]'),
  footer: $('[data-target-footer]'),
  footerShow: $('[data-target-footerShow]'),
  footerBanner: $('[data-target-footerBanner]'),
  cta: $('[data-target-cta]'),
  window: $(window),
}

export function toTop() {
  if (!target.toTop.length) return
  $(document).on('scroll', () => {
    const scrollDistance = $(document).scrollTop()
    if (scrollDistance > 200) {
      target.toTop.addClass('is-show')
    } else {
      target.toTop.removeClass('is-show')
    }
  })
}

export function headerShow() {
  if (!target.header.length) return
  $(document).on('scroll', () => {
    const headerHeight = target.header.height()
    const aboutPosition = target.headerShow.offset().top
    const showHeight = aboutPosition - headerHeight
    const scrollDistance = $(document).scrollTop()
    if (scrollDistance > showHeight) {
      target.header.addClass('is-show')
    } else {
      target.header.removeClass('is-show')
    }
  })
}

export function footerBanner() {
  if (!target.header.length) return
  $(document).on('scroll', () => {
    const windowHeight = target.window.height()
    const footerBannerHeight = target.footerBanner.height()
    const aboutPosition = target.headerShow.offset().top
    const footerPosition = $('.c-form__footer').offset().top
    const showPosition = aboutPosition - windowHeight
    const hidePosition = footerPosition - footerBannerHeight - windowHeight
    const scrollDistance = $(document).scrollTop()
    if (scrollDistance > showPosition) {
      target.footerBanner.addClass('is-show')
      if (scrollDistance > hidePosition) {
        target.footerBanner.removeClass('is-show')
      }
    } else {
      target.footerBanner.removeClass('is-show')
    }
  })
}
